import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LightboxContext } from "./layout";
import '../styles/media-grid.scss';

const returnFormatClass = formatArr => {
  if(formatArr.value.length <= 0) {
    return '';
  } else {
    return formatArr.value[0].codename === 'landscape' ? 'media-grid__img--landscape' : '';
  }
}

const MediaGrid = ({data}) => {
  let lightboxSettings = useContext(LightboxContext);
  return (
    <LightboxContext.Consumer>
      {
        ({ setCurrentImage }) => {
          return (
            <ul class="media-grid">
            {
              data.map(media => {
                switch(media.elements.type.value[0].codename) {
                  case "video": 
                    return <li class={`media-grid__video`}>
                      <iframe src={`https://youtube.com/embed/${media.elements.youtube_id.value}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true" webkitallowfullscreen="true"></iframe>
                    </li>
                  case "clip": 
                    return <li class={`media-grid__video`}>
                    <video playsinline controls="true" autoPlay="true" muted="true" loop="true">
                      <source src={`${media.elements.media_asset.value[0].url}`}>
                      </source>
                    </video>
                  </li>
                  default: 
                    var format = returnFormatClass(media.elements.format);
                    return <li onClick={ 
                      () => setCurrentImage({ 
                        url: media.elements.media_asset.value[0].url,
                        caption: media.elements.caption.value,
                        variant: media.elements.format.value[0].codename
                      })
                    }
                    class={`media-grid__img ${format}`}><img src={media.elements.media_asset.value[0].url} alt={media.elements.caption.value}/>
                    </li>
                }
              })
            }
          </ul>
          )
        }
      }
      </LightboxContext.Consumer>
  );
};

export default MediaGrid;

MediaGrid.propTypes = {
  data: PropTypes.array,
};