import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import MediaGrid from '../components/media-grid';
import SEO from "../components/seo"
import "../styles/collection.scss";

const Collection = ({data}) => {
  const item = data.kenticoCloudItemCollection;
  return (
    <Layout>
      <section class="collection">
        <SEO title={item.elements.collection_name.value} />
        <h2>{item.elements.collection_name.value}</h2>
        <MediaGrid data={item.elements.media_items}/>
      </section>
    </Layout>
  );
};

export default Collection;

export const query = graphql`
  query collectionQuery($slug: StringQueryOperatorInput) {
    kenticoCloudItemCollection(elements: { url: { value: $slug }}) {
      system {
        name
      }
      elements {
        url {
          value
        }
        collection_name {
          value
        }
        media_items {
          elements {
            caption {
              value
            }
            media_asset {
              value {
                url
              }
            }
            youtube_id {
              value
            }
            type {
              value {
                codename
              }
            }
            format {
              value {
                codename
              }
            }
          }
        }
      }
    }
  }
`;

Collection.propTypes = {
  data: PropTypes.object,
};